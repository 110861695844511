export const PRODUCT_CARD_WIDTH = 300;
export const PRODUCT_CARD_HEIGHT = 300;

export const CATEGORY_IMAGE_WIDTH = 592;
export const CATEGORY_IMAGE_HEIGHT = 300;

export const BANNER_IMAGE_WIDTH = 912;
export const BANNER_IMAGE_HEIGHT = 550;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 335;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 210;

export const MOBILE_BANNER_IMAGE_WIDTH = 390;
export const MOBILE_BANNER_IMAGE_HEIGHT = 280;

export const TESTIMONIALS_IMAGE_WIDTH = 100;
export const TESTIMONIALS_IMAGE_HEIGHT = 100;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 100;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 100;

export const DEFAULT_LAYOUT_SELECTION = 7;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH1';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB8';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS1';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS1';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial4';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = false;

export const SECTION_HEADING_CLASSNAME = 'font-signika-bold md:font-semibold';
export const SLIDER_TOP_PADDING = 'pt-0';
export const LAYOUT_CONFIG = {};
export const COMMON_TOAST_MESSAGE = {};
