import StoreInfo from '@common/models/StoreInfo';
import { CustomPagesSliceType } from '@common/store/customPages/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import { isMobile } from '@common/utils';
import Email from '@images/Email.svg';
import facebook from '@images/Ffacebook.svg';
import Instagram from '@images/Finstagram.svg';
import LinkedIn from '@images/Flinkedin.svg';
import Pinterest from '@images/Fpinterest.svg';
import Twitter from '@images/FTwitter.svg';
import Youtube from '@images/Fyoutube.svg';
import Phone from '@images/Phone-call.svg';
import Pin from '@images/Pin.svg';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';

interface FooterProps {
  storeInfo: StoreInfo;
  customPages: CustomPagesSliceType;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
  pageHeader: PageHeaderSliceType;
  pageHeaderID: number;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  storeInfo,
  features,
  filterBar,
  policies: { policyTos },
  pageHeader,
  pageHeaderID,
}) => {
  const quickLinks =
    'text-center md:text-left md:w-full w-fit mx-auto md:mx-0 mb-14  flex text-lalit-bannerText  text-16 md:text-14 leading-24 md:leading-20 font-medium';
  const header = 'text-center md:text-left text-white leading-30 text-21 mb-18 font-signika capitalize';
  const arePoliciesPresent = Object.keys(policyTos).length > 0;
  const pageHeaderLayout = pageHeader[pageHeaderID]?.pageHeader;
  return (
    <div className="w-full bg-lalit-heading" color-customisation="footer-bg" id="page-footer">
      <div className="w-full container mx-auto pt-42 pb-20">
        <div className="">
          <div className="block  py-12 md:py-0 ">
            <Link
              to="/"
              className="flex rounded justify-center md:justify-start w-111 mx-auto mb-32 overflow-hidden"
              id="footer-logo"
            >
              {pageHeaderLayout?.logo ? (
                <Image
                  noDefaultDimensions
                  src={pageHeaderLayout?.logo}
                  alt={storeInfo?.storename}
                  className="overflow-hidden object-contain h-44"
                />
              ) : (
                <div className="md:font-medium text-20 text-white ">{storeInfo?.storename}</div>
              )}
            </Link>
          </div>
          <div className=" md:justify-between justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-1 md:px-4">
              {/* <div className="md:block hidden">
                <Link to="/">
                  {storeInfo?.logo ? (
                    <Image
                      noDefaultDimensions
                      src={storeInfo?.logo}
                      alt={storeInfo?.storename}
                      className=" h-44 rounded"
                    />
                  ) : (
                    <div className="font-medium text-30 text-white">{storeInfo?.storename}</div>
                  )}
                </Link>
              </div> */}
              <div className="mb-50 md:mb-0">
                <div className={header} color-customisation="footer-heading" font-customisation="section-heading">
                  Menu
                </div>
                {arePoliciesPresent && !policyTos?.privacy && (
                  <Link
                    to="/policy/privacy"
                    className={quickLinks}
                    color-customisation="footer-links"
                    font-customisation="para-text"
                    id="footer-privacy"
                  >
                    Privacy
                  </Link>
                )}
                {arePoliciesPresent && !policyTos?.termsAndConditions && (
                  <Link
                    to="/policy/tnc"
                    className={quickLinks}
                    color-customisation="footer-links"
                    font-customisation="para-text"
                    id="footer-terms-conditions"
                  >
                    Terms & Conditions
                  </Link>
                )}
                {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                  <Link
                    to="/contactus"
                    className={quickLinks}
                    color-customisation="footer-links"
                    font-customisation="para-text"
                    id="footer-contact-us"
                  >
                    Contact Us
                  </Link>
                ) : null}
                {getActiveNavbar('About Us', filterBar) && (
                  <Link
                    to="/aboutus"
                    className={quickLinks}
                    color-customisation="footer-links"
                    font-customisation="para-text"
                    id="footer-about-us"
                  >
                    About Us
                  </Link>
                )}
                {getActiveNavbar('FAQs', filterBar) && (
                  <Link
                    to="/faqs"
                    className={quickLinks}
                    color-customisation="footer-links"
                    font-customisation="para-text"
                    id="footer-faqs"
                  >
                    FAQs
                  </Link>
                )}
              </div>
              <div className="mb-50 md:mb-0">
                <div className={header} color-customisation="footer-heading" font-customisation="section-heading">
                  Account
                </div>
                <Link
                  to="/track-order"
                  className={quickLinks}
                  color-customisation="footer-links"
                  font-customisation="para-text"
                  id="footer-track-order"
                >
                  Track Order
                </Link>
                {arePoliciesPresent && !policyTos?.cancelAndRefund && (
                  <Link
                    to="/policy/cancellation"
                    className={quickLinks}
                    color-customisation="footer-links"
                    font-customisation="para-text"
                    id="footer-cancellation-refund"
                  >
                    Cancellation &amp; Refund
                  </Link>
                )}
                {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
                  <Link
                    to="/policy/shipping"
                    className={quickLinks}
                    color-customisation="footer-links"
                    font-customisation="para-text"
                    id="footer-shipping-delivery"
                  >
                    Shipping &amp; Delivery
                  </Link>
                )}
                {/* {customPages?.pages?.results?.map((page) => {
                  if ((getBuyerJWTToken() && page.protected) || !page.protected) {
                    return (
                      <Link
                        key={page.id}
                        to={page.url}
                        className={quickLinks}
                        color-customisation="footer-links"
                        font-customisation="para-text"
                      >
                        {page.name}
                      </Link>
                    );
                  } else {
                    return null;
                  }
                })} */}
              </div>
              <div className="mb-50 md:mb-0">
                <div className={header} color-customisation="footer-heading" font-customisation="section-heading">
                  Company
                </div>

                <div className=" text-priceText ">
                  <p
                    className={quickLinks}
                    color-customisation="footer-links"
                    font-customisation="para-text"
                    id="footer-contact-address"
                  >
                    <Image noDefaultDimensions={true} src={Pin} alt="Email" className="mr-14 hidden" />
                    {storeInfo?.contactInfo?.address}
                  </p>
                  <p className={quickLinks} color-customisation="footer-links" font-customisation="para-text">
                    <Image
                      noDefaultDimensions={true}
                      src={Phone}
                      alt="Phone"
                      className="mr-14 hidden"
                      style={{ filter: 'brightness(0.3)' }}
                    />
                    <a href={`tel:${storeInfo?.contactInfo?.number}`} id="footer-contact-number">
                      {storeInfo?.contactInfo?.number}
                    </a>
                  </p>
                  <p className={quickLinks} color-customisation="footer-links" font-customisation="para-text">
                    <Image
                      noDefaultDimensions={true}
                      src={Email}
                      alt="Email"
                      className="mr-14 hidden"
                      style={{ filter: 'brightness(0.3)' }}
                    />
                    <a
                      href={`${isMobile() ? 'mailto:' : 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to='}${
                        storeInfo?.contactInfo?.email
                      }`}
                      className="underline"
                    >
                      <p className="break-all" id="footer-contact-mail">
                        {storeInfo?.contactInfo?.email}
                      </p>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {storeInfo?.hasSocialLinks ? (
            <div className="md:mb-0 flex w-full justify-center py-30 md:pt-40 md:pb-0 items-center border-t border-white border-opacity-10 md:border-opacity-0">
              <div
                className="text-lalit-bannerText text-normal  text-16 leading-24 flex min-w-80"
                color-customisation="footer-heading"
                font-customisation="para-text"
              >
                Follow us
              </div>
              <div className="flex gap-20 md:gap-34 ml-20 md:ml-32 w-fit items-center h-fit">
                {storeInfo?.social?.facebook ? (
                  <a className={''} href={storeInfo?.social?.facebook} target="_black()" title="Facebook">
                    <Image
                      noDefaultDimensions={true}
                      src={facebook}
                      alt="Facebook"
                      className="w-20 min-w-20 h-20 min-h-20 opacity-50"
                      id="footer-facebook-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.twitter ? (
                  <a className={''} href={storeInfo?.social?.twitter} target="_black()" title="Twitter">
                    <Image
                      noDefaultDimensions={true}
                      src={Twitter}
                      alt="Twitter"
                      className=" w-20 min-w-20 h-20 min-h-20 opacity-50"
                      id="footer-twitter-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.instagram ? (
                  <a className={''} href={storeInfo?.social?.instagram} target="_black()" title="Instagram">
                    <Image
                      noDefaultDimensions={true}
                      src={Instagram}
                      alt="Instagram"
                      className=" w-20 min-w-20 h-20 min-h-20 opacity-50"
                      id="footer-instagram-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.pinterest ? (
                  <a className={''} href={storeInfo?.social?.pinterest} target="_black()" title="Pinterest">
                    <Image
                      noDefaultDimensions={true}
                      src={Pinterest}
                      alt="Pinterest"
                      className=" w-20 min-w-20 h-20 min-h-20 opacity-50"
                      id="footer-pinterest-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.youtube ? (
                  <a className={''} href={storeInfo?.social?.youtube} target="_black()" title="YouTube">
                    <Image
                      noDefaultDimensions={true}
                      src={Youtube}
                      alt="Youtube"
                      className=" w-20 min-w-20 h-20 min-h-20 opacity-50"
                      id="footer-youtube-link"
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.linkedin ? (
                  <a className={''} href={storeInfo?.social?.linkedin} target="_black()" title="LinkedIn">
                    <Image
                      noDefaultDimensions={true}
                      src={LinkedIn}
                      alt="LinkedIn"
                      className=" w-20 min-w-20 h-20 min-h-20 opacity-50"
                      id="footer-linkedin-link"
                    />
                  </a>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default connect(({ pageHeader }: RootState) => ({ pageHeader }))(Footer);
